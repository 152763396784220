<template>
  <div>
    <AbstractUserProfile
      :username="username"
      :userType="userType"
      :user="user"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractUserProfile from "../components/Abstract/Users/AbstractUserProfile.vue";
export default {
  name: "UserProfile",
  components: {
    AbstractUserProfile,
  },
  computed:{
    ...mapGetters(["userTokenInfo"]),
    user(){
      return this.userTokenInfo;
    },
    userType(){
      return this.user?.utype;
    },
    username(){
      return this.user?.uname;
    },
  }
};
</script>

<style></style>
