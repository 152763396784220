<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-flex class="mb-4">
          <h2>{{ userTitleType }}</h2>
        </v-flex>
        <v-text-field
          filled
          readonly
          v-model="username"
          label="Usuario"
        ></v-text-field>
      </v-card-text>
    </v-card>

    <v-card v-if="isManagerUser" class="mt-3">
      <v-card-subtitle class="mt-0 mx-4">
        Establecimientos Asociados
        <v-divider></v-divider>
        <div>
          <p
            v-if="isReleatedEstablishmentsEmpty"
            class="button text-center text--disabled pa-5 text--lighten-5"
          >
            Todavia no hay establecimientos asociados
          </p>
          <v-chip
            v-else
            v-for="releatedEstablishment in releatedEstablishments"
            :key="releatedEstablishment.id"
            class="ma-2"
            @click="goToEstablishment(releatedEstablishment.id)"
          >
            {{ releatedEstablishment.name }}
            ({{ releatedEstablishment.regionName }}) -
            {{ releatedEstablishment.hectares }}ha
          </v-chip>
        </div>
      </v-card-subtitle>
      <v-card-subtitle class="mt-0 mx-4">
        Engagements Asociados
        <v-divider></v-divider>
        <div>
          <p
            v-if="isReleatedEngagementsEmpty"
            class="button text-center text--disabled pa-5 text--lighten-5"
          >
            Todavia no hay engagements asociados
          </p>
          <v-chip
            v-else
            v-for="releatedEngagement in releatedEngagements"
            :key="releatedEngagement.engagementId"
            class="ma-2"
            @click="goToEngagement(releatedEngagement.engagementId)"
          >
            {{ releatedEngagement.campaignName }}v{{
              releatedEngagement.campaignVersion
            }}
            - ( Id:{{ releatedEngagement.engagementId }})
            {{ releatedEngagement.establishmentName }}
            {{ releatedEngagement.region }}-
            {{ releatedEngagement.totalHectares }} Hectáreas
          </v-chip>
        </div>
      </v-card-subtitle>
      <v-card-subtitle class="mx-4">
        Draft Engagements Asociados
        <v-divider></v-divider>
        <div>
          <p
            v-if="isReleatedDraftEngagementsEmpty"
            class="button text-center text--disabled pa-5 text--lighten-5"
          >
            Todavia no hay draft engagements asociados
          </p>
          <v-chip
            v-else
            v-for="releatedDraftEngagement in releatedDraftEngagements"
            :key="releatedDraftEngagement.draftEngagementId"
            class="ma-2"
            @click="
              goToDraftEngagement(releatedDraftEngagement.draftEngagementId)
            "
          >
            ( Id:{{ releatedDraftEngagement.draftEngagementId }})
            {{ releatedDraftEngagement.establishmentName }}
            {{ releatedDraftEngagement.region }} -
            {{ releatedDraftEngagement.totalHectares }}
            Hectáreas
          </v-chip>
        </div>
      </v-card-subtitle>
    </v-card>
    <div v-if="isAdminUser" class="mt-3">
      <v-row no-gutters>
        <v-col sm="5" offset-sm="2" md="6" offset-md="0">
          <v-btn
            color="red white--text px-2"
            dark
            @click.stop="deleteCampaignDialog = true"
          >
            <v-icon> mdi-delete </v-icon>
            <span class="px-2">ELIMINAR UNA CAMPAÑA</span>
          </v-btn>

          <v-dialog v-model="deleteCampaignDialog" max-width="800">
            <v-card>
              <v-card-title class="text-h5">
                ¿Esta seguro que desea eliminar una Campaña
                <strong>POR COMPLETO</strong> ?
              </v-card-title>

              <v-card-text>
                Al apretar <strong>Eliminar</strong> se borraran todos los datos
                ingresados para esta <strong>CAMPAÑA</strong> , junto con
                <strong>TODAS SUS VERSIONES</strong> y no podran ser
                recuperados.
              </v-card-text>
              <v-card-text>
                <v-data-table
                  dense
                  multi-sort
                  :options="{ itemsPerPage: 100 }"
                  :headers="
                    [
                      { name: 'Nombre', value: 'name' },
                      { name: 'Fecha de inicio', value: 'startDate' },
                      { name: 'Fecha de finalización', value: 'endDate' },
                    ].map((i) => {
                      return { text: i.name, value: i.value };
                    })
                  "
                  :items="processedAbstractCampaigns"
                  show-select
                  single-select
                  v-model="selected"

                ></v-data-table>
                <div class="">
                  <v-text-field
                    v-model="inputName"
                    label="Nombre de Campaña"
                    type="text"
                    single-line
                  ></v-text-field>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="green darken-1"
                  text
                  @click="deleteCampaignDialog = false"
                >
                  Cancelar
                </v-btn>

                <v-btn
                  color="green darken-1"
                  text
                  @click="deleteCompleteCampaign"
                >
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteRequest } from "../../../managrx/serverCall";
export default {
  name: "AbstractUserProfile",
  props: {
    username: String,
    userType: String,
    engagementsIds: Array,
    draftEngagementsIds: Array,
    user: Object,
  },
  data() {
    return {
      deleteCampaignDialog: false,
      inputName: "",
      selected: [],
    };
  },
  beforeMount() {
    if (this.isManagerUser) {
      this.$store.dispatch("getAbstractCampaigns");
      this.$store.dispatch("getCampaigns");
      this.$store.dispatch("getEstablishments");
      this.$store.dispatch("getCampaignEngagements");
      this.$store.dispatch("getDraftEngagements");
      this.$store.dispatch("getEngagements");
    }
    if (this.isAdminUser) {
      this.$store.dispatch("getAbstractCampaigns");
      this.$store.dispatch("getCampaigns");
    }
  },
  methods: {
    async deleteCompleteCampaign() {
      this.deleteCampaignDialog = false;
      let deleteCampaign = true;
      if (this.selectedCampaignToDelete?.name != this.inputName) {
        deleteCampaign = false;
        let notif = {
          success: false,
          data: {
            text: "El nombre de campaña ingresado no es correcto",
            type: "alert",
          },
        };
        this.$store.dispatch("setNewNotification", notif);
      }
      if (deleteCampaign) {
        let response = await deleteRequest(
          `/module/delete/abstract_campaign/${this.selectedCampaignToDelete?.id}`
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          let notif = {
            success: true,
            data: {
              text: "Campaña eliminada con exito",
              type: "success",
            },
          };
          this.$store.dispatch("setNewNotification", notif);
          this.$store.dispatch("getAbstractCampaigns");
          this.$store.dispatch("getCampaigns");
        }
      } else {
        this.inputName = null;
        this.inputVersion = null;
      }
    },
    goToDraftEngagement(id) {
      this.$router.push({ name: "SingleDraftEngagement", params: { id: id } });
    },
    goToEngagement(id) {
      this.$router.push({ name: "SingleEngagement", params: { id: id } });
    },
    goToEstablishment(id) {
      this.$router.push({ name: "SingleEstablishment", params: { id: id } });
    },

  },
  computed: {
    ...mapGetters([
      "draftEngagements",
      "campaignEngagements",
      "establishments",
      "abstractCampaigns",
    ]),
    selectedCampaignToDelete(){
      return this.selected[0]
    },
    userTitleType() {
      if (this.userType == "admin") {
        return "Administrador";
      } else if (this.userType == "director") {
        return "Director";
      } else if (this.userType == "manager") {
        return "Manager";
      } else {
        return "No user Type";
      }
    },
    processedAbstractCampaigns() {
      return this.abstractCampaigns.map((i) => {
        return {
          id: i.id,
          name: i.name,
          startDate: new Date(i.startDate)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
          endDate: new Date(i.endDate)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
        };
      });
    },
    parseManager() {
      if (this.userType == "manager") {
        let userCompletInfo = this.user;
        userCompletInfo.engagements = this.campaignEngagements?.items.filter(
          (item) => this.user?.elist.includes(item.engagementId)
        );
        userCompletInfo.draftEngagements = this.draftEngagements?.items.filter(
          (item) => this.user?.delist.includes(item.draftEngagementId)
        );
        userCompletInfo.establishments = this.establishments.filter((item) =>
          this.user?.estlist.includes(item.id)
        );
        return userCompletInfo;
      } else {
        return {};
      }
    },
    isManagerUser() {
      return this.userType == "manager";
    },
    isAdminUser() {
      return this.userType == "admin";
    },
    isReleatedEstablishmentsEmpty() {
      return this?.releatedEstablishments?.length === 0;
    },
    isReleatedEngagementsEmpty() {
      return this?.releatedEngagements?.length === 0;
    },
    isReleatedDraftEngagementsEmpty() {
      return this?.releatedDraftEngagements?.length === 0;
    },
    releatedDraftEngagements() {
      return this.parseManager?.draftEngagements;
    },
    releatedEngagements() {
      return this.parseManager?.engagements;
    },
    releatedEstablishments() {
      return this.parseManager?.establishments;
    },
  },
};
</script>
